import { StandaloneComponent, StandaloneComponentProps } from '@/types/component';
import { mergeOptions, mergeProps } from '@/utils/merge';
import { ComponentProps, ReactNode, useRef, useState } from 'react';
import { useEffectOnce, useToggle } from 'react-use';
import { cancelSubscription, defaultStatuses, getPaymentsItems, GetPaymentsResponse } from '..';
import { DigitalSubscriptionDetails } from './Digital';

export interface StandaloneSubscriptionDetailsDigitalProps
  extends StandaloneComponentProps<typeof DigitalSubscriptionDetails> {
  headline?: ReactNode;
  preSubscriptionDescription?: ReactNode;
  subscriptionDescription?: ReactNode;
  contactInfo?: ReactNode;
  history?: ComponentProps<typeof DigitalSubscriptionDetails.History>;
  confirmationDialog?: ComponentProps<typeof DigitalSubscriptionDetails.Dialog>;
  cancelSubscriptionButton?: ComponentProps<typeof DigitalSubscriptionDetails.Button>;
  paymentHistoryButton?: ComponentProps<typeof DigitalSubscriptionDetails.Button>;
}

export const StandaloneSubscriptionDetailsDigital: StandaloneComponent<StandaloneSubscriptionDetailsDigitalProps> = ({
  headline,
  preSubscriptionDescription,
  subscriptionDescription,
  contactInfo,
  confirmationDialog,
  cancelSubscriptionButton,
  paymentHistoryButton,
  history,
  ...props
}) => {
  const [isHistoryOpen, toggleIsHistoryOpen] = useToggle(false);
  const cancelConfirmationDialogRef = useRef<HTMLDialogElement>(null);
  const [isCancellingSubscription, toggleIsCancellingSubscription] = useToggle(false);
  const [paymentHistory, setPaymentHistory] = useState<GetPaymentsResponse | undefined>();

  useEffectOnce(() => {
    async function fetchData() {
      const data = await getPaymentsItems();
      setPaymentHistory(data);
    }
    fetchData();
  });

  const showButtons = Boolean(cancelSubscriptionButton || paymentHistoryButton);

  const isActive = paymentHistory?.status === 'active';
  const isCancelled = Boolean(paymentHistory?.cancelDate);
  const isPreSubscription = !isActive && !isCancelled;

  return (
    <DigitalSubscriptionDetails {...props}>
      {headline && <DigitalSubscriptionDetails.Headline>{headline}</DigitalSubscriptionDetails.Headline>}
      {isPreSubscription && preSubscriptionDescription && (
        <DigitalSubscriptionDetails.Description>{preSubscriptionDescription}</DigitalSubscriptionDetails.Description>
      )}
      {!isPreSubscription && subscriptionDescription && (
        <DigitalSubscriptionDetails.Description>{subscriptionDescription}</DigitalSubscriptionDetails.Description>
      )}
      <DigitalSubscriptionDetails.DetailsList>
        <DigitalSubscriptionDetails.Detail>
          Påbörjad: <b>{paymentHistory?.startDate || '-'}</b>
        </DigitalSubscriptionDetails.Detail>
        <DigitalSubscriptionDetails.Detail>
          {isActive ? 'Förnyas' : 'Giltig till'}: <b>{paymentHistory?.endDate || '-'}</b>
        </DigitalSubscriptionDetails.Detail>
        <DigitalSubscriptionDetails.Detail>
          Status: <b>{paymentHistory?.status ? defaultStatuses[paymentHistory.status] : '-'}</b>
        </DigitalSubscriptionDetails.Detail>
      </DigitalSubscriptionDetails.DetailsList>
      {showButtons && (
        <DigitalSubscriptionDetails.ButtonGroup>
          {cancelSubscriptionButton && (
            <DigitalSubscriptionDetails.Button
              {...mergeProps(
                {
                  options: mergeOptions(
                    {
                      onClick: () => cancelConfirmationDialogRef.current?.showModal(),
                      disabled: isCancellingSubscription || isCancelled || isPreSubscription,
                    },
                    props.options?.$button,
                  ),
                },
                cancelSubscriptionButton,
              )}
            />
          )}
          {paymentHistoryButton && (
            <DigitalSubscriptionDetails.Button
              {...mergeProps(
                {
                  options: mergeOptions(
                    { colors: 'secondary', onClick: () => toggleIsHistoryOpen() },
                    props.options?.$button,
                  ),
                },
                paymentHistoryButton,
              )}
            />
          )}
        </DigitalSubscriptionDetails.ButtonGroup>
      )}
      {contactInfo && <DigitalSubscriptionDetails.ContactInfo>{contactInfo}</DigitalSubscriptionDetails.ContactInfo>}
      {isHistoryOpen && history && (
        <DigitalSubscriptionDetails.History
          {...mergeProps(
            {
              items: paymentHistory?.payments?.map(({ date, description, amount, currency }) => ({
                date,
                description,
                price: `${amount / 100} ${currency}`,
              })),
            },
            { options: props.options?.$history },
            history,
          )}
        />
      )}
      {confirmationDialog && (
        <DigitalSubscriptionDetails.Dialog
          onPrimaryButtonClick={
            confirmationDialog.onPrimaryButtonClick ||
            (async () => {
              try {
                toggleIsCancellingSubscription();
                await cancelSubscription({
                  subscriptionId: paymentHistory?.subscriptionId || '',
                });
                setPaymentHistory(await getPaymentsItems());
                toggleIsCancellingSubscription();
              } catch (error) {
                console.error('Error cancelling subscription:', error);
                toggleIsCancellingSubscription();
              }
            })
          }
          {...confirmationDialog}
          options={{ ref: cancelConfirmationDialogRef, ...confirmationDialog.options }}
        />
      )}
    </DigitalSubscriptionDetails>
  );
};
